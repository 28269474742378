import React, {useEffect} from "react";
import Modal  from "react-modal";
import {Helmet} from "react-helmet";
import { useState } from "react";

Modal.setAppElement("#root");

export const Logindemo = (props) => {
  const [validUserId, setValidUserId] = useState(false);
  const [validPwd, setValidPwd] = useState(false);
  const [loading, setLoading] = useState(false);  
  const [userID, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [compregkeyid, setCompregkeyid] = useState('');
  const [status, setStatus] = useState('');
  const [loginTrigger, setLoginTrigger] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loginMsgs, setLoginMsgs] = useState("Welcome");
  const [selectedDemo, setSelectedDemo] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  
  // const [data, setData] = useState([]);
  // const [options, setOptions] = useState([]);
  // const [groupedData, setGroupedData] = useState({});
  // const [videoUri, setVideoUri] = useState('');
  // const [videoFileName, setVideoFileName] = useState('');
  const [videos, setVideos] = useState([]);
  // const [videoCategory, setVideoCategory] = useState('');
  // const videoId = "WWmbk-yP8h0"; 
//  const BASE_URL = 'https://iclvideos.s3.ap-south-1.amazonaws.com/icligm/'; 

  // const handlevButtonStateChange=(videoFileName)=>{
  //   const encodedFileName = encodeURIComponent(videoFileName);  
  //   const videoUri = `${BASE_URL}${encodedFileName}`;
  //   console.log("Button pressed");
  // }
  // const handleDemoChange = (event) => {
  //   setSelectedDemo(event.target.value);
  // };

  const validateUserId = (userId) => {
    // Regular expression for mobile validation
    const uiRegex = /^[ei][0-9]{3,5}$/;
    return uiRegex.test(userId);
  };
  // Input change handler
const handleInputChange = (event) => {
  const userId = event.target.value; // Get the input field value
  const isValid = validateUserId(userId); // Validate the input

  if (isValid) {     
    setValidUserId(true);
    setUserId(userId);
  } else {
    setValidUserId(false);
//    console.error("Invalid User ID:", userId); // Example: Log invalid ID
  }
};

const validatePwd = (pwd) => {
  // Regular expression for password validation
  const pwdRegex = /^[a-zA-Z ]{4}[0-9]{3,4}$/;
  return pwdRegex.test(pwd);
};
// Input change handler
const handlePwdChange = (event) => {
const pwd = event.target.value; // Get the input field value
const isValid2 = validatePwd(pwd); // Validate the input

if (isValid2) {     
  setValidPwd(true);
  setPassword(pwd);
} else {
  setValidPwd(false);
}
};

// LogIn trigger
const handleLoginButton = (event) => {
  setLoginTrigger(true);
  };  
useEffect(() => {
  const fetchData = async () => {
    try {
      console.log("Aproaching API ...");      
      const response = await fetch(`https://servguest.eximon.com/AndyCon?app=Demo_videos&userid=${encodeURIComponent(userID)}&password=${encodeURIComponent(password)}`);

      if (!response.ok) {
        throw new Error('Network response was not OK');
      }
      const responseData = await response.text();
      // Process the responseData as needed
      console.log('Data received:', responseData);
      const lines = responseData.split('\n');
      let msg='';
//      let sqlflag='';
      for (let line of lines) { //Data
        if (line.trim().startsWith("sqlflag")) { 
          msg = line.trim().substring(12);
          //sqlflag=msg;
          console.log("sqlflag:"+msg);
        }
        if (line.trim().startsWith("compregkeyid")) { 
          msg = line.trim().substring(12);
          setCompregkeyid(msg);
          if(msg ==='' || msg==='0') {
             setModalIsOpen(false);
             setLoginMsgs('Invalid credentials');
          } else {
              setModalIsOpen(true);
              setLoginMsgs('Welcome');
          }
          console.log("Guest ID:"+msg);
        }
        if (line.trim().startsWith("status")) { 
          msg = line.trim().substring(6);
          setStatus(msg);
          console.log("Status:"+msg);
        }
      }      
      
    } catch (error) {
      console.error('API error:', error.message);
    }
  }; 
   if(validPwd && validUserId && loginTrigger) {
    fetchData(); 
    setLoginTrigger(false);
  }
}, [validPwd, validUserId, password, userID, loginTrigger]);

//Videos
useEffect(() => {
  const fetchData = async () => {
    try {
      console.log("fetching videos of..."+status);  
      let softName = 'ICLIGM';
      if(status === 'EE') {
        softName = 'EMSS';
        setBaseUrl('https://iclvideos.s3.ap-south-1.amazonaws.com/emss/');
      }
      else {
        softName = 'ICLIGM';
        setBaseUrl('https://iclvideos.s3.ap-south-1.amazonaws.com/icligm/');
      }
      const response = await fetch(`https://servguest.eximon.com/AndyCon?app=IR_iclvideos&software=${encodeURIComponent(softName)}`);
      const json = await response.json();
      const demoVideos = json.iclvideos.filter(video=>
         video.category==="Demos" 
      );
      setVideos(demoVideos);
//      groupDataByCategory(json.iclvideos);
      console.log("fetching completed.");  
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  fetchData();

}, [status]);

// const handleVideoChange = (event) => {
//   setSelectedDemo(event.target.value);
// };

// const groupDataByCategory = (videos) => {
//   if (!videos || videos.length === 0) {
//     console.warn('No videos available to group!');
//     return {};
//   }

//   const groupedData = videos.reduce((acc, video) => {
//     (acc[video.category] = acc[video.category] || []).push(video);
//     return acc;
//   }, {});
//   setData(groupedData);
// };

return (  
  <div id="logindemo" className="text-center">
      <Helmet>
      <meta charSet="utf-8" lang="en" />
      
      <link rel="canonical" href="https://eximon.com/#logindemo" />
      <meta name="description" content="Software, Database, Cloud, ChatBot services for Importers Exporters and Maritime shipping companies." />
      <meta name="keywords" content="Mobile App Development, App Design, App Development Company, Mobile App Builder, App Prototyping, Mobile UX/UI Design, App Store Optimization, Mobile App Marketing, App Testing, SCMTR, maritime shipping, importers-exporters, Customs, ICEGATE, Cross-Platform Development" />
      <meta property="og:title" content="Sign In - ICL Software Demos" />
    </Helmet>

      <div className="container">
        <div className="section-title">
          <h1>Sign In - ICL Software Demos</h1>
          <h2>Self help portal for ICL-Software services</h2>
          <p id='loginMsgs' style={{ textAlign: 'center' }}>{loginMsgs}</p>
       
          <div className="form-group" style={{ width: "300px", margin: "auto" }}>
          
            <label htmlFor="userId" className="font-weight-bold text-light" style={{ marginTop: "10px", fontSize:"16px" }}>
    User ID:
  </label>
            <input type="text" id="userId" className="form-control" placeholder="Enter User ID" onChange={handleInputChange} 
            style={{borderColor:validUserId?'green':'#cc5500'}}
            />

            <label htmlFor="password" className="font-weight-bold text-light" style={{ marginTop: "10px", fontSize:"16px" }}>
    Password:
  </label>

            <input type="password" id="password" className="form-control" placeholder="Enter Password" onChange={handlePwdChange} 
            style={{borderColor:validPwd?'green':'#cc5500'}}
            />
<div className="btn-group" style={{ marginTop: "20px" }}>
            <button className="btn btn-custom btn-lg" onClick={handleLoginButton} >Log In</button>
            {/* <button className="btn btn-custom btn-lg" style={{ marginTop: "20px" }}>Sign up</button>    */}
            <a href="#contact" class="btn btn-custom btn-lg">Sign ip</a>               
            </div>
            <Modal 
                  isOpen={modalIsOpen} 
                  onRequestClose={() => setModalIsOpen(false)}
                  style={{
                    content: {
                      position: 'absolute',
                      top: '100px',
                      left: '80px',
                      right: 'auto',
                      bottom: 'auto',
                      width: '80%',
                      height: '70%'
                    }
                  }}
                >
                  {loading && <div style={{fontWeight:"bold"}}>Please wait loading ...</div>}
                  <div>
      <label htmlFor="dropdown" className="font-weight-bold text-light">Choose demo to play: </label>
      <select id="dropdown" value={selectedDemo} onChange={(e) => setSelectedDemo(e.target.value)} style={{ marginLeft: '10px' }}>
      <option value="">--Select a Video--</option>
        {videos.map((video) => (
          <option key={video.keyid} value={video.video}>
            {video.caption}
          </option>
          ))}
      </select>
      
      {/* {selectedDemo && <p>You selected: {selectedDemo}</p>} */}
    </div>
    {selectedDemo && (
      <video
        src={`${baseUrl}${selectedDemo}`}
        controls
        controlsList="nodownload"
        style={{ width: '100%', height: '80%', marginTop: '10px', marginBottom: '10px' }}
        onLoadedData={() => setLoading(false)}
      >
        Your browser does not support the video tag. {compregkeyid}
      </video>
    )}
                  {!loading && 
                                  <button onClick={()=>{setModalIsOpen(false);setLoading(true);}} type="button" className="btn btn-custom btn-lg" style={{marginTop:'5px'}} >
                            Close
                          </button> 
          }               
                  </Modal>    
          </div>

        </div>
      </div>
    </div>
    
  );
};
