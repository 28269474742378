import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';

 Modal.setAppElement('#root');

export const Icligm = (props) => {
  const [data, setData] = useState({ paras: "Loading..." });
  const [dataH, setDataH] = useState({ parasH: "Loading..." });
  const [dataT, setDataT] = useState({ parasT: "Loading..." });

  const [expanded, setExpanded] = useState([]);
  const [loading, setLoading] = useState(true); // Initialize loading state
  const [loadingch, setLoadingch] = useState(true); // Initialize loading state chat

  //  const [isLoading, setIsLoading] = useState(false);
  const videoId = "FJOBxlkGYMo";

  const [modalIsOpen, setModalIsOpen] = useState(false);// for right bottom frame
  const [modalIsOpen2, setModalIsOpen2] = useState(false);// for ICLIGM video frame
  
  // const [isIBVisible, setIsIBVisible] = useState(false);
  //   const toggleIBVisibility = () => {
  //       setIsIBVisible(!isIBVisible);
  //       if(!isIBVisible) {
  //       setIsLoading(true);
  //       }
  //   };
  //  
//   <Helmet>
//   <script type="application/ld+json">
//   {`
//     {
//       "@context": "https://schema.org",
//       "@type": "VideoObject",
//       "name": "ICLIGM an Enterprise Software Solution for Maritime Shipping Companies, Forwarders, NVOCC and Liners",
//       "description": "ICLIGM an Enterprise Software Solution for Shipping Companies, Shipping Agents, Freight forwarders, NVOCC and Liners.
//       Available for MAC, Linux and Windows O/S.
//       ICEGATE - S.C.M.T.R. Compatible Software that handles the complete procedure, documents, and formats required for customs and ports.
//       Available in three options: Client Server, SaaS (pay per use), and Cloud back-end.
//       In use by hundreds of shipping companies since 1998 and has a transparent customer support policy.
//       Security & Compliance: ICLIGM is built with robust security features and ensures compliance with all relevant regulations.
//       Trusted by Leading Businesses:
//       ICLIGM™ is an enterprise software solution of choice for hundreds of businesses across India, from small and medium-sized enterprises to large corporations.      
//       Get Started Today:
//       Ready to experience the transformative power of ICLIGM™? Schedule a free demo today and see how our ICLIGM software solution can help you simplify your operations, reduce costs, and achieve your business goals.      
//       Visit our website: https://eximon.com      
//       Contact us: +91-22-20888 880 / 1 / 99696 30299 / 97573 61777
//       #EXIM #ICLIGM #IGM #EGM #ICEGATE #DGFT #Customs #EXIMsoftware #EXIMerp #EMSS #CloudEXIM #OnPremiseEXIM #India",
//       "duration": "PT7M14S",
//       "thumbnailUrl": [
//         "https://img.youtube.com/vi/FJOBxlkGYMo/maxresdefault.jpg"
//       ],
//       "uploadDate": "2021-01-25T08:00:00+08:00",
//       "contentUrl": "https://youtu.be/FJOBxlkGYMo"
//     }
//   `}
//   </script>
// </Helmet>
  useEffect(() => {
  
    fetch('https://servguest.eximon.com/ServConI?page=ICLIGM-React')
      .then((response) => response.text())
      .then((fetchedData) => {
        setLoading(false); // Data is available, set loading to false
        const lines = fetchedData.split('\n');
        const H_lines = fetchedData.split('\n');
        const tag_line = fetchedData.split('\n');
        let tData = {
            rititleh : "ICLIGM Title data not found ..."
        }
        
        let icligmData = {
            B_rihigh : "ICLIGM Highlights data not found ...",            
            C_riusp : "ICLIGM USP data not found ...",            
            D_ritm : "ICLIGM Testing Merging data not found ...",            
            E_risaas : "ICLIGM SaaS data not found ...",
            F_ricloud : "ICLIGM Cloud data not found ...",
            H_riim : "ICLIGM Import Module data not found ...",
            G_riem : "ICLIGM Export Module data not found ...",
            I_rict : "ICLIGM Container tracking data not found ...",
            J_riweb : "ICLIGM Web data not found ...",
            L_rigsp : "ICLIGM Support policy not found ...",
            K_rirv : "ICLIGM Riview and Validate data not found ...",
            M_ribi : "ICLIGM B/L Info data not found ...",
            N_riun : "ICLIGM UN EDIfact data not found ...",
          }
          let hData = {
            B_rihighh : "ICLIGM Highlights header not found ...",        
            C_riusph : "ICLIGM USP header not found ...",                    
            D_ritmh : "ICLIGM Testing Merging header not found ...",
            E_risaash : "ICLIGM SaaS header not found ...",
            F_ricloudh : "ICLIGM Cloud header not found ...",
            H_riimh : "ICLIGM Import Module header not found ...",
            G_riemh : "ICLIGM Export Module header not found ...",
            I_ricth : "ICLIGM Container tracking header not found ...",
            J_riwebh : "ICLIGM Web header not found ...",
            L_rigsph : "ICLIGM support policy header not found ...",                                
            K_rirvh : "ICLIGM Riview and Validate header not found ...",
            M_ribih : "ICLIGM B/L Info header not found ...",
            N_riunh : "ICLIGM UN EDIfact header not found ...",
          }

        for (let Tline of tag_line) { //Tag line
          if (Tline.trim().startsWith("rititleh")) {
            tData.rititleh = Tline.trim().substring(8);
          }         
        }
          for (let line of lines) { //Data
          if (line.trim().startsWith("rihigh")) { //Highlights 2,18
            icligmData.B_rihigh = line.trim().substring(6);
          }         
          if (line.trim().startsWith("riusp")) { //USP 2,19
            icligmData.C_riusp = line.trim().substring(5);
          }
          if (line.trim().startsWith("ritm")) { //Testing Merging 2,13
            icligmData.D_ritm = line.trim().substring(4);
          }                                        
          if (line.trim().startsWith("risaas")) { //SaaS 2,21
            icligmData.E_risaas = line.trim().substring(6);
          }          
          if (line.trim().startsWith("ricloud")) { //Cloud 2,22
            icligmData.F_ricloud = line.trim().substring(7);
          }  
          if (line.trim().startsWith("riim")) { //Import Module 2,11
            icligmData.H_riim = line.trim().substring(4);
          }                                        
          if (line.trim().startsWith("riem")) { //Export Module 2,12
            icligmData.G_riem = line.trim().substring(4);
          }                                        
          if (line.trim().startsWith("rict")) { //Container tracking 2,14
            icligmData.I_rict = line.trim().substring(4);
          }                                        
          if (line.trim().startsWith("riweb")) { //Web Module 2,17
            icligmData.J_riweb = line.trim().substring(5);
          }                    
          if (line.trim().startsWith("rigsp")) { //Support policy 2,20
            icligmData.L_rigsp = line.trim().substring(5);
          }          
          if (line.trim().startsWith("rirv")) { //R&V 2,10
            icligmData.K_rirv = line.trim().substring(4);
          }  
          if (line.trim().startsWith("ribi")) { //B/L Info App 2,16
            icligmData.M_ribi = line.trim().substring(4);
          }                              
          if (line.trim().startsWith("riun")) { //UN EDIfact
            icligmData.N_riun = line.trim().substring(4);
          }                                        
        }
        for (let Hline of H_lines) { //Headlines
          if (Hline.trim().startsWith("rihighh")) {
            if(Hline.trim().substring(7)!=null) {
            hData.B_rihighh = Hline.trim().substring(7);
            }
          }                             
          if (Hline.trim().startsWith("riusph")) {
            if(Hline.trim().substring(6)!=null) {
            hData.C_riusph = Hline.trim().substring(6);
            }
          }
          if (Hline.trim().startsWith("ritmh")) { //Testing Merging
            if(Hline.trim().substring(5)!=null) {
            hData.D_ritmh = Hline.trim().substring(5);
            }
          }                                        
          if (Hline.trim().startsWith("risaash")) { //SaaS
            if(Hline.trim().substring(7)!=null) {
            hData.E_risaash = Hline.trim().substring(7);
            }
          }          
          if (Hline.trim().startsWith("ricloudh")) { //Cloud
            if(Hline.trim().substring(8)!=null) {
            hData.F_ricloudh = Hline.trim().substring(8);
            }
          }                                        
          if (Hline.trim().startsWith("riimh")) { //Import Module 2,11
            if(Hline.trim().substring(5)!=null) {
            hData.H_riimh = Hline.trim().substring(5);
            }
          }                                        
          if (Hline.trim().startsWith("riemh")) { //Export Module 2,12
            if(Hline.trim().substring(5)!=null) {
            hData.G_riemh = Hline.trim().substring(5);
            }
          }                                        
          if (Hline.trim().startsWith("ricth")) { //Container tracking 2,14
            if(Hline.trim().substring(5)!=null) {
            hData.I_ricth = Hline.trim().substring(5);
            }
          }                                        
          if (Hline.trim().startsWith("riwebh")) { //Web Module
            if(Hline.trim().substring(6)!=null) {
            hData.J_riwebh = Hline.trim().substring(6);
            }
          }                    
          if (Hline.trim().startsWith("rigsph")) { //support policy
            if(Hline.trim().substring(6)!=null) {
            hData.L_rigsph = Hline.trim().substring(6);
            }
          }          
          if (Hline.trim().startsWith("rirvh")) { //R&V 2,10
            if(Hline.trim().substring(5)!=null) {
            hData.K_rirvh = Hline.trim().substring(5);
            }
          }                                        
          if (Hline.trim().startsWith("ribih")) { //B/L Info App
            if(Hline.trim().substring(5)!=null) {
            hData.M_ribih = Hline.trim().substring(5);
            }
          }                              
          if (Hline.trim().startsWith("riunh")) { //UN EDIfact
            if(Hline.trim().substring(5)!=null) {
            hData.N_riunh = Hline.trim().substring(5);
            }
          }                                        

        }
        // //sorting
        // Create an array of keys in alphabetical order
const sortedKeys = Object.keys(icligmData).sort();
const sortedHKeys = Object.keys(hData).sort();

// Create a new object to store the sorted data
const sortedIcligmData = {};
const sortedHIcligmData = {};

// Iterate through the sorted keys and assign values to the sorted data object
for (const key of sortedKeys) {
  sortedIcligmData[key] = icligmData[key];
}

// Set the sorted data in the 'paras' property of prevData
setData((prevData) => ({ ...prevData, paras: Object.values(sortedIcligmData) }));

// Iterate through the sorted keys and assign values to the sorted data object
for (const key of sortedHKeys) {
  sortedHIcligmData[key] = hData[key];
}

// Set the sorted data in the 'paras' property of prevData
setDataH((prevData) => ({ ...prevData, parasH: Object.values(sortedHIcligmData) }));

// Set the sorted data in the 'paras' property of prevData
setDataT((prevData) => ({ ...prevData, parasT: tData.rititleh }));

      })
      .catch((error) => {
        console.error('Error fetching data:', error.message);
      });
  },[]);

  // Function to toggle the "Read more" state

  const toggleReadMore = (index) => {

  const updatedStates = [...expanded];
  updatedStates[index] = !expanded[index];
  setExpanded(updatedStates);
   };
    
//    const handleLoad = () => { //for chatBot button
//     setIsLoading(false);
// };
  // Function to create an HTML element from the string
  const createMarkup = (htmlString) => ({ __html: htmlString });

  const maxLines = 1; // The maximum number of lines to display

  // Function to limit the number of lines displayed
  const truncateText = (text) => {
    if (text) {
      const div = document.createElement('div');
      div.innerHTML = text;
  
      const paras = div.getElementsByTagName('p');
      if (paras.length > maxLines) {
        for (let i = maxLines; i < paras.length; i++) {
          paras[i].style.display = 'none';
        }
      }
  
      return div.innerHTML;
    }
    return text;
  };

  return (
    
    <div id="icligm" className="text-center">
      <Helmet>
      <meta charSet="utf-8" lang="en" />
      {/* <title>ICLIGM Software</title> */}
      <link rel="canonical" href="https://eximon.com/#icligm" />
      <meta name="description" content="ICLIGM AN ENTERPRISE SOFTWARE SOLUTION FOR MARITIME SHIPPING COMPANIES, SHIPPING AGENCIES, FREIGHT FORWARDERS, NVOCC AND LINERS." />
      <meta name="keywords" content="Enterprise Software Solution for Maritime, Maritime Shipping Companies Software, Shipping Agencies Software, Freight Forwarders Software, NVOCC Software Solutions, Liner Software Solutions, ICLIGM Software, Maritime Enterprise Solutions, Shipping Management Software, Freight Forwarding Solutions, NVOCC Business Solutions, Liner Management Software" />
      <meta property="og:title" content="ICLIGM Software" />
      <meta property="og:type" content="video.movie" />
      <meta property="og:url" content="https://youtu.be/FJOBxlkGYMo" />
    </Helmet>
      <div className="container">
        <div className="section-title">
          <h1>ICLIGM Software</h1>  
          <h2 dangerouslySetInnerHTML={{ __html: dataT.parasT }}></h2>
        </div>
        <div className="btn-group">                
                <button onClick={()=>setModalIsOpen2(true)}  type="button" className="btn btn-custom btn-lg">
                  ICLIGM Demo (Intro)
                </button>
                <Modal 
        isOpen={modalIsOpen2} 
        onRequestClose={() => setModalIsOpen2(false)}
        style={{
          content: {
            position: 'absolute',
            top: 'auto',
            left: '10px',
            right: 'auto',
            bottom: '20px',
            width: '70%',
            height: '60%'
          }
        }}
      >
                        {loadingch && <div style={{fontWeight:"bold"}}>Please wait loading ...</div>}
        <iframe 
          title="ICLIGM Demo (Intro)" 
          src={`https://www.youtube.com/embed/${videoId}?rel=0`}  
          allowFullScreen 
          style={{ width: '100%', height: '90%' }} onLoad={()=>setLoadingch(false)}
        />
        {!loadingch && 
                        <button onClick={()=>{setModalIsOpen2(false);setLoadingch(true);}} type="button" className="btn btn-custom btn-lg" >
                  Close
                </button>
}                
        </Modal> 
        <a href="#logindemo" class="btn btn-custom btn-lg">LogIn Demos</a>                
                <button onClick={()=>setModalIsOpen(true)} type="button" className="btn btn-custom btn-lg" >
                  ICL chatBot
                </button>                
                <Modal 
        isOpen={modalIsOpen} 
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          content: {
            position: 'absolute',
            top: 'auto',
            left: 'auto',
            right: '0px',
            bottom: '20px',
            width: '400px',
            height: '500px'
          }
        }}
      >
                        {loadingch && <div style={{fontWeight:'bold'}}>Please wait loading ...</div>}

        <iframe 
          title="ICL chatBot" 
          src="https://dtegnbzmkqshu.cloudfront.net" 
          allowFullScreen 
          style={{ width: '100%', height: '90%' }} onLoad={()=>setLoadingch(false)}
        />
        {!loadingch && 
                        <button style={{marginTop:'5px'}} onClick={()=>{setModalIsOpen(false);setLoadingch(true);}} type="button" className="btn btn-custom btn-lg" >
                  Close
                </button>
}                
        </Modal>
                                
                </div>
        {/* <div class="video-container">
      <iframe class="video-iframe" 
        src={`https://www.youtube.com/embed/${videoId}?rel=0`} 
        title="ICLIGM an Enterprise Software Solution for Maritime Shipping Companies, Forwarders, NVOCC and Liners" 
        frameborder="0" 
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen loading="lazy" 
      ></iframe>
    </div> */}
        <div className="row" style={{marginTop:'10px'}}>
          {loading
            ? "Loading..." // Display loading message
            : props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <i className={d.icon}></i>
                  <h3>{dataH.parasH[i]}</h3>
                 
{/* Render limited lines with "Read more" link */}
 <div
                 className={`icligm-paras ${expanded[i] ? 'expanded' : 'truncated'}`}
                 dangerouslySetInnerHTML={createMarkup(
                   expanded[i] ? data.paras[i] : truncateText(data.paras[i] || '')
                 )}
               />
               {data.paras[i] && data.paras[i].length > maxLines && (
                 <button onClick={() => toggleReadMore(i)} className="btn-custom">
                   {expanded[i] ? 'Read less' : 'Read more'}
                 </button>
               )}
                </div>
              ))
            : "No data available"}
        </div>

      </div>
    </div>
  );
};